<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";

import appConfig from "@/app.config";
import blogSvc from "@/services/blog";
import { toast } from "vue3-toastify";

/**
 * Customers component
 */
export default {
  page: {
    title: "Blog Highlight",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: { Layout, PageHeader },
  data() {
    return {
      title: "Blog Highlight",
      items: [
        {
          text: "Blog",
          href: "#",
        },
        {
          text: "Highlight",
          active: true,
        },
      ],
      columns: [
        {
          field: "uuid",
          hidden: true,
        },
        {
          field: "statusText",
          hidden: true,
        },
        {
          label: "Name",
          field: "name",
        },
        {
          label: "Status",
          field: "statusCode",
          filterOptions: {
            enabled: true,
            placeholder: "Filter Status",
            filterDropdownItems: [
              { value: "created", text: "Draft" },
              { value: "pending_verify", text: "Pending Verify" },
              // { value: "approved", text: "Approved" },
              { value: "publish", text: "Publish" },
              { value: "editing", text: "Editing" },
              { value: "rejected", text: "Rejected" },
              { value: "unpublish", text: "Unpublish" },
            ],
            trigger: "enter",
          },
        },
        {
          label: "Highlight",
          field: "isHighlight",
        },
        {
          label: "Created By",
          field: "createdBy",
        },
        {
          label: "Created Date",
          field: "createdAt",
          type: "Date",
        },
        {
          label: "Last Updated",
          field: "updatedAt",
          type: "Date",
        },
      ],
      rows: [],
    };
  },
  mounted() {
    this.get();
  },
  methods: {
    get() {
      blogSvc.getBlogHighlight().then((resp) => {
        if (resp.data.data === null) {
          return;
        }
        this.rows = resp.data.data;
      });
    },
    updateBlogHighlight(uuid) {
      blogSvc
        .updateBlogHighlight(uuid)
        .then((resp) => {
          if (resp.status === 200) {
            toast.success("Update Highlight Successfully", {
              autoClose: localStorage.getItem("toastCountdown"),
            });
          } else {
            toast.error("Update Highlight Failed", {
              autoClose: localStorage.getItem("toastCountdown"),
            });
          }
        })
        .catch((err) => {
          toast.error(err, {
            autoClose: localStorage.getItem("toastCountdown"),
          });
        })
        .finally(() => {
          this.get();
        });
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />

    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="table-responsive">
              <vue-good-table
                :search-options="{
                  enabled: true,
                }"
                :pagination-options="{
                  enabled: true,
                  perPage: 10,
                  mode: 'records',
                }"
                :line-numbers="true"
                styleClass="tableOne vgt-table striped"
                :columns="columns"
                :rows="rows"
              >
                <template #table-row="props">
                  <span v-if="props.column.field == 'statusCode'">
                    <b-badge
                      v-if="
                        [
                          'created',
                          'pending_verify',
                          'editing',
                          'draft',
                          'updated',
                        ].includes(props.row.statusCode)
                      "
                      variant="warning"
                      >{{ props.formattedRow["statusText"] }}</b-badge
                    >
                    <b-badge
                      v-else-if="
                        ['rejected', 'unpublish'].includes(props.row.statusCode)
                      "
                      variant="danger"
                      >{{ props.formattedRow["statusText"] }}</b-badge
                    >
                    <b-badge
                      v-else-if="
                        ['approved', 'publish', 'publish_draft'].includes(
                          props.row.statusCode
                        )
                      "
                      variant="success"
                      >{{ props.formattedRow["statusText"] }}</b-badge
                    >
                    <b-badge v-else>-</b-badge>
                  </span>
                  <span v-else-if="props.column.field == 'isHighlight'">
                    <b-form-checkbox
                      v-model="props.formattedRow.isHighlight"
                      name="check-button"
                      switch
                      @change="updateBlogHighlight(props.formattedRow.uuid)"
                    >
                      <span
                        class="badge rounded-pill font-size-12 badge-soft-success"
                        v-if="props.formattedRow.isHighlight"
                        >Yes</span
                      >
                      <span
                        class="badge rounded-pill font-size-12 badge-soft-warning"
                        v-else
                        >No</span
                      >
                    </b-form-checkbox>
                  </span>
                  <span v-else>
                    {{ props.formattedRow[props.column.field] }}
                  </span>
                </template>
              </vue-good-table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- end row -->
  </Layout>
</template>
